import { css } from 'styled-components';
import { fonts, colors } from '../../../style-utilities/variables';

export default css`
  a {
    color: ${colors.bodyText};
    text-decoration: none;
  }
  h4 {
    color: ${colors.bodyText};
    text-decoration: none;
  }
`;
