import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import { SearchStateConsumer } from '../context/SearchContext';
import PopularSearchTerms from '../components/PopularSearchTerms';
import ResultsSection from '../components/SearchResults/ResultsSection';
import ComponentStyles from '../brand-styles/pages/search-results.styles';
import { colors, fonts } from '../style-utilities/variables';
import { Helmet } from 'react-helmet';
import { capitalize } from '../utilities/stringUtils';

const StyledSearchResults = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 50px;
  padding-top: 50px;

  &.has-results {
    h1 {
      font-size: 18px;
    }
  }

  .results-section {
    padding: 2%;
  }

  .no-results {
    height: calc(100vh - 262px);
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const NoSearchResults = styled.div`
  max-width: 1400px;
  text-align: center;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  @media (min-width: 768px) {
    height: calc(100vh - 262px);
  }
  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const StyledSearchBar = styled.div`
  padding: 20px 10px;
  @media (min-width: 425px) {
    padding: 20px 50px;
  }
  @media (min-width: 674px) {
    padding: 50px;
  }

  .search-btn {
    background-color: ${colors.primary};
    float: right;
    position: relative;
    top: -41px;
    right: 4px;
    color: #fff;
    border: none;
    padding: 10px 15px;
  }

  form,
  input {
    width: 100%;
  }
`;

class SearchResults extends Component {
  state = {
    searchTerm: '',
    currentQueryString: ''
  };

  //All main ingredients from cms
  allMainIngredient = this.props.data.allWordpressWpMainIngredient.edges;

  updateSearchTerm(term) {
    this.setState({
      searchTerm: term
    });
  }

  async componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let term = params.get('term');
    if (term) {
      this.setState({
        searchTerm: term,
        currentQueryString: term
      });
      await this.props.searchContext.updateSearchTerm(term);
      this.updateSearchTerm(term);
      this.props.searchContext.searchSubmit();
    }
  }

  componentDidUpdate() {
    window.onpopstate = e => {
      let search = e.target.location.search;
      let params = new URLSearchParams(search);
      let term = params.get('term');
      if (term) {
        this.props.searchContext.updateSearchTerm(term);
        this.updateSearchTerm(term);
        this.props.searchContext.searchSubmit();
      }
    };
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Search Our Site | {capitalize(this.props.siteTitle)}</title>
          <meta
            name="description"
            content={process.env.SITE_META_SEARCH_PAGE_DESCRIPTION}
          />
        </Helmet>
        <SearchStateConsumer>
          {searchContext => {
            return searchContext.searchResults.recipes.length > 0 ||
              searchContext.searchResults.recipes.length > 0 ||
              (searchContext.searchResults.other &&
                searchContext.searchResults.other.length > 0) ? (
              <StyledSearchResults className="page-container has-results">
                <StyledSearchBar>
                  <form
                    onSubmit={event => {
                      event.preventDefault();
                      searchContext.searchSubmit();
                      this.updateSearchTerm(searchContext.searchTerm);
                      event.target.reset();
                    }}
                  >
                    <input
                      type="text"
                      value={searchContext.searchTerm}
                      onChange={event =>
                        searchContext.updateSearchTerm(event.target.value)
                      }
                    />
                    <button type="submit" className="search-btn">
                      search
                    </button>
                  </form>
                </StyledSearchBar>
                <h1>
                  {this.props.siteTitle.includes('-es')
                    ? 'Resultados Principales Para'
                    : 'Top Results for'}{' '}
                  "{this.state.searchTerm}" ({searchContext.totalResults})
                </h1>
                <ResultsSection
                  siteTitle={this.props.siteTitle}
                  type={
                    this.props.siteTitle.includes('-es') ? 'recetas' : 'recipes'
                  }
                  searchResults={searchContext.searchResults.recipes}
                  allMainIngredient={this.allMainIngredient}
                  updateFavoritesCount={
                    this.props.userContext.updateFavoritesCount
                  }
                  authenticatedUser={this.props.userContext.authenticatedUser}
                />
                <ResultsSection
                  siteTitle={this.props.siteTitle}
                  type={
                    this.props.siteTitle.includes('-es')
                      ? 'productos'
                      : 'products'
                  }
                  searchResults={searchContext.searchResults.products}
                />
                <ResultsSection
                  siteTitle={this.props.siteTitle}
                  type={this.props.siteTitle.includes('-es') ? 'otra' : 'other'}
                  searchResults={searchContext.searchResults.other}
                />
              </StyledSearchResults>
            ) : (
              <NoSearchResults className="page-container no-results">
                <StyledSearchBar>
                  <form
                    onSubmit={event => {
                      event.preventDefault();
                      searchContext.searchSubmit();
                      this.updateSearchTerm(searchContext.searchTerm);
                    }}
                  >
                    <input
                      type="text"
                      value={searchContext.searchTerm}
                      onChange={event =>
                        searchContext.updateSearchTerm(event.target.value)
                      }
                    />
                    <button type="submit" className="search-btn">
                      Search
                    </button>
                  </form>
                </StyledSearchBar>
                {searchContext.requestPending ? (
                  <>
                    <p>Searching...</p>
                  </>
                ) : (
                  <>
                    {this.props.siteTitle.includes('-es') ? (
                      <>
                        <p>
                          Lo sentimos, su búsqueda de "{this.state.searchTerm}"
                          no tiene ningún resultado!
                        </p>
                        <p>
                          Intente nuevamente con una palabra clave diferente, o
                          seleccione cualquiera de estas palabras de búsqueda
                          populares.
                        </p>
                      </>
                    ) : (
                      <>
                        <p>
                          Sorry, your search for "{this.state.searchTerm}" did
                          not yield any results!
                        </p>
                        <p>
                          Try again using a different keyword, or select any of
                          these popular search words.
                        </p>
                      </>
                    )}
                  </>
                )}
                <PopularSearchTerms
                  searchContext={searchContext}
                  siteTitle={this.props.siteTitle}
                />
              </NoSearchResults>
            );
          }}
        </SearchStateConsumer>
      </>
    );
  }
}

export default SearchResults;

export const query = graphql`
  query {
    allWordpressWpMainIngredient {
      edges {
        node {
          name
          wordpress_id
          slug
        }
      }
    }
  }
`;
