import React from 'react';
import styled from 'styled-components';
import ComponentStyles from '../brand-styles/components/PopularSearchTerms.styles';
import { colors, fonts } from '../style-utilities/variables';

const StyledPopularSearchTerms = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  button {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
    padding: 10px;
    width: 9em;
    background-color: #163d7c;
    background-color: ${colors.primary};
    border: none;
    color: #fff;
    @media (min-width: 460px) {
      width: 6.5em;
    }
    @media (min-width: 540px) {
      width: 7.5em;
    }
    @media (min-width: 600px) {
      width: 8.5em;
    }
    @media (min-width: 1024px) {
      width: 9.5em;
    }
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const PopularSearchTerms = ({ searchContext, siteTitle }) => {
  let terms;
  if (siteTitle === 'minute') {
    terms = ['Vegetarian', 'Quick & Easy', 'Lunch', 'Whole Grain'];
  } else if (siteTitle === 'success') {
    terms = ['Casserole', 'Lunch', 'Vegetarian'];
  } else {
    terms = ['Vegetarian', 'Quick & Easy', 'Lunch', 'Whole Grain'];
  }
  return (
    <StyledPopularSearchTerms>
      {terms.map(term => (
        <button
          key={term}
          onClick={async () => {
            await searchContext.updateSearchTerm(term.toLowerCase());
            searchContext.searchSubmit();
          }}
        >
          {term}
        </button>
      ))}
    </StyledPopularSearchTerms>
  );
};

export default PopularSearchTerms;
