import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'gatsby';

import { capitalize } from '../../utilities/stringUtils';
import { breakpoints, colors } from '../../style-utilities/variables';
import RecipeCard from '../RecipeCard';
import PlaceHolderImage from '../../images/placeholder.png';
import ComponentStyles from '../../brand-styles/components/SearchResults/ResultsSection.styles';

const StyledProductResults = styled.ul`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  li {
    list-style: none;
    width: 100%;
    img {
      max-width: 100%;
    }
  }
  a {
    color: ${colors.bodyText};
    text-decoration: none;
  }

  @media (min-width: ${breakpoints.desktop}) {
    li {
      width: 33%;
    }
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const StyledSearchResults = styled.div`
  ul {
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    a {
      margin: 10px;
    }
    a .recipe-card {
      margin: 0 !important;
    }
  }
`;

const StyledOtherResults = styled.div`
  p.link-more {
    display: none;
  }
`;

const ResultsSection = ({
  type,
  searchResults,
  allMainIngredient,
  updateFavoritesCount,
  authenticatedUser,
  siteTitle
}) => {
  return (
    <div>
      <h2>
        {siteTitle.includes('-es') && type !== 'otra'
          ? `Resultados de Búsqueda de ${capitalize(type)} (${
              searchResults.length
            })`
          : siteTitle.includes('-es') && type === 'otra'
          ? `Resultados de ${capitalize(type)} Búsqueda (${
              searchResults.length
            })`
          : `Search Results for ${capitalize(type)} (${searchResults.length})`}
      </h2>
      {(type === 'recipes' || type === 'recetas') && (
        <StyledSearchResults>
          <ul>
            {searchResults.map(result => {
              const mainIngredientSlugs = allMainIngredient.filter(el =>
                result.main_ingredient.some(e => e === el.node.wordpress_id)
              );
              return (
                <RecipeCard
                  className="recipe-card"
                  updateFavoritesCount={updateFavoritesCount}
                  authenticatedUser={authenticatedUser}
                  key={result.id}
                  id={result.id}
                  title={result.title.rendered}
                  imageUrl={
                    result.acf.thumbnail
                      ? result.acf.thumbnail
                      : PlaceHolderImage
                  }
                  mainIngredient={
                    mainIngredientSlugs[0]
                      ? mainIngredientSlugs[0].node.name
                      : ''
                  }
                  cookTime={result.acf.total_time}
                  slug={result.slug}
                />
              );
            })}
          </ul>
        </StyledSearchResults>
      )}
      {(type === 'products' || type === 'productos') && (
        <StyledProductResults>
          {searchResults.map(result => {
            return (
              <li key={result.id}>
                <Link to={`/products/${result.slug}`}>
                  <img
                    src={
                      result.acf.product_image
                        ? result.acf.product_image.sizes.medium
                        : ''
                    }
                    alt={result.title.rendered}
                  />
                  <h4>{ReactHtmlParser(result.title.rendered)}</h4>
                </Link>
              </li>
            );
          })}
        </StyledProductResults>
      )}
      {(type === 'other' || type === 'otra') && (
        <StyledOtherResults>
          <ul>
            {searchResults.map(result => {
              return (
                <li style={{ listStyle: 'none' }} key={result.id}>
                  <h3>{ReactHtmlParser(result.title.rendered)}</h3>
                  {ReactHtmlParser(result.excerpt.rendered)}
                  <Link to={result.slug.replace('home', '')}>Read More</Link>
                </li>
              );
            })}
          </ul>
        </StyledOtherResults>
      )}
    </div>
  );
};

export default ResultsSection;
